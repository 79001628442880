
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































.blog-hero {
  position: relative;
  overflow: hidden;
}

.blog-hero__bg {
  @include center-x;

  content: '';
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  height: 30%;
  background: $c-mustard;

  @include mq(m) {
    top: 0;
    bottom: unset;
    left: 0;
    width: 50%;
    height: 100%;
    transform: none;
  }
}

.blog-hero-wrapper {
  position: relative;
  z-index: 1;
}

::v-deep .crumbs {
  // Change crumbs color to white
  .crumbs__item {
    &::after {
      color: $c-white;
    }
  }

  [class*='action--'][class*='--link'][class*='--colored'] {
    color: $c-white;

    &:hover,
    &:focus {
      color: lighten($c-mustard, 30%);
    }
  }

  // Display crumbs as mobile until xl
  @include mq($until: 'xl') {
    .crumbs__item {
      /* stylelint-disable-next-line */
      .action__icon {
        display: block;
      }

      &::after {
        content: none;
      }

      &:not(:nth-last-child(2)) {
        position: absolute;
        overflow: hidden;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
        clip: rect(0 0 0 0);
      }
    }
  }
}

.blog-hero__content {
  $padding-v: (
    xxs: 20px,
    m: 40px,
    xl: 60px,
  );
  $padding-h: (
    xxs: 20px,
    m: 40px,
    xl: 60px,
  );

  @extend %border-radius;
  @include fluid(padding-top, $padding-v);
  @include fluid(padding-right, $padding-h);
  @include fluid(padding-bottom, $padding-v);
  @include fluid(padding-left, $padding-h);

  margin-top: 20rem;
  background: $c-white;
  border-radius: 10px;

  @include mq(m) {
    width: col(5.5, 10);
    margin-top: 0;
  }
}

.blog-hero__content__date {
  @extend %fw-medium;
  @extend %ff-default;

  color: $c-mustard;
  font-size: 2rem;
  font-style: italic;
}

.blog-hero__content__label {
  @extend %ff-alt;
  @extend %border-radius-small;

  z-index: 1;
  width: fit-content;
  padding: 0 $spacing * 0.7;
  color: $c-mustard;
  font-size: 1.5rem;
  border: 0.1rem $c-mustard solid;
}

.blog-hero__content__title {
  max-width: none;
}

.blog-hero__content__subtitle {
  ::v-deep em {
    color: currentColor;
  }
}

.blog-hero__content__author {
  @include default-margin(0.4);

  display: flex;
  align-items: flex-start;
  padding-top: $spacing;
  text-align: left;
  border-top: 0.1rem solid $c-light-grey;

  ::v-deep .author__picture {
    margin-right: $spacing;
    margin-left: 0;
  }
}

[class*='blog-hero__visual--'] {
  @include get-all-space;

  z-index: -1;
  overflow: hidden;
  height: 70%;

  @include mq('m') {
    left: unset;
    width: 50%;
    height: 100%;
  }
}

.blog-hero__visual--picture {
  ::v-deep .picture {
    height: 100%;
  }

  ::v-deep .picture__container {
    border-radius: 0;
  }
}
