
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































































































.hero-default {
  --spacing: 30px;

  @include fluid(
    --spacing,
    (
      xxs: 30px,
      m: 35px,
      xl: 45px,
    )
  );

  position: relative;
  transform: translateX(0); // for fixed grandchildren

  &.cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: var(--header-height);
  }

  &:not(.cover) {
    padding-bottom: 0;
  }

  @include mq($until: l) {
    &.cover {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @include mq(l) {
    &.cover {
      aspect-ratio: 16/9;
      max-height: 75vh;

      &::before {
        @include get-all-space;

        z-index: -1;
        content: '';
        background: rgba($c-black, 50%);
      }
    }
  }
}

.hero-default-inner {
  position: relative;
  display: flex;
  flex-direction: column;
}

.hero-default__crumbs {
  order: -2;

  @include mq(l) {
    .hero-default.cover & {
      position: fixed;
      top: 4rem;
      max-width: none;
    }
  }

  @include mq(l) {
    .hero-default.cover & {
      position: fixed;
      top: 4rem;
      max-width: none;
    }
  }
}

.hero-default__title,
.hero-default__subtitle,
.hero-default__intro {
  @include mq(s) {
    padding-right: 12rem;
  }

  @include mq(l) {
    // Leave space for promo label (15rem + spacing)
    .has-promo:not(.cover) & {
      padding-right: 18rem;
    }
  }

  @include mq(l) {
    .hero-default.cover & {
      max-width: 70rem;
      padding-right: 0;
      color: $c-white;
      text-shadow: 0 0 1rem rgba($c-black, 0.2);
    }
  }
}

.hero-default__label {
  @extend %ff-alt;
  @extend %border-radius-small;

  z-index: 1;
  order: -1;
  width: fit-content;
  padding: 0 $spacing * 0.7;
  color: $c-mustard;
  font-size: 1.5rem;
  border: 0.1rem $c-mustard solid;
}

.hero-default__intro {
  .hero-default.cover & {
    display: none;
  }
}

.hero-default__link {
  .hero-default.cover & {
    color: $c-white;

    /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
    &:hover ::v-deep .action__label::before {
      color: $c-white;
    }
  }
}

.hero-default__promo {
  margin: $spacing * 1.5 auto 0;

  .hero-default.cover & {
    position: absolute;
    top: 0;
    right: var(--wrapper-padding);
    margin-top: 0;
    transform: translateY(-100%);
  }

  @include mq(s) {
    @include center-y;

    right: var(--wrapper-padding);
    margin: 0;
  }

  @include mq(l) {
    .hero-default.cover & {
      position: fixed;
      top: initial;
      right: var(--wrapper-padding);
      bottom: 0;
      transform: translateY(25%);
    }
  }
}

.hero-default__video,
.hero-default__picture {
  width: 100%;
  max-height: 90vh;
  aspect-ratio: 4/3;
  margin-top: var(--spacing);

  .hero-default.cover & {
    order: -1;
    aspect-ratio: 16/9;
    margin-top: 0;
    margin-bottom: var(--spacing);
  }

  @include mq(l) {
    aspect-ratio: 16/9;

    .hero-default.cover & {
      @include get-all-space;

      z-index: -2;
      max-height: none;
      margin: 0;
      aspect-ratio: unset;

      video,
      img {
        @include image-fit;
      }
    }
  }
}
