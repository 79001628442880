
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























/* stylelint-disable
   selector-class-pattern,
   selector-max-combinators,
   selector-max-compound-selector,
   selector-max-compound-selectors */

.flexible > * {
  @include default-margin(0.6);

  &:last-child {
    margin-bottom: $spacing * 3;

    &.cpt-ctaText,
    &.cpt-ctaType {
      margin-bottom: 0;
    }

    @include mq(l) {
      margin-bottom: $spacing * 4;
    }
  }
}

::v-deep .bordered {
  .bordered + & {
    margin-top: 0;

    > * {
      border-top: 0;
    }
  }

  &.no-border-bottom {
    > * {
      border-bottom-width: 0;
    }
  }

  &.no-border-top {
    > * {
      border-top-width: 0;
    }
  }
}

.cpt-experts,
.cpt-expertstest {
  position: relative;
  overflow: hidden;
}
