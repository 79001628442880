
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































































































































.before-onboard {
  opacity: 0;
}

.blog-single__promo {
  position: relative;
  z-index: 1;
  margin-top: $spacing * -1;

  @include mq(s) {
    margin-top: $spacing * -1.5;
  }

  @include mq(m) {
    margin-top: -8rem;
  }
}

.blog-single__promo__cta {
  margin: 0 auto;

  @include mq(m) {
    margin-right: 0;
  }
}

.ctas {
  @include default-margin();
}

.share {
  margin-top: 0;
}
