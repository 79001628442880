
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































.hero-promo {
  @extend %text-center;

  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 9.7rem;
  height: 9.7rem;
  color: $c-mustard;
  text-decoration: none;
  background: $c-white;
  border-radius: 50%;
  box-shadow: 0 2rem 6rem rgba(0, 0, 0, 0.15);
  transition: background 0.5s $ease-softer, color 0.5s $ease-softer;

  &:hover,
  &:focus-visible {
    color: $c-white;
    background: $c-mustard;
  }

  &.inverted {
    color: $c-white;
    background: $c-mustard;

    &:hover,
    &:focus-visible {
      color: $c-mustard;
      background: $c-white;
    }
  }

  @include mq(l) {
    width: 15rem;
    height: 15rem;
  }
}

.hero-promo__label {
  @extend %ff-alt;
  @extend %fw-medium;
  @extend %text-uppercase;

  font-size: 1.4rem;
  line-height: 1.3;

  span {
    display: block;
    font-size: 2.4rem;
    line-height: 1;
  }

  @include mq(l) {
    font-size: 1.8rem;

    span {
      font-size: 3.6rem;
    }
  }
}

.hero-promo__arrow {
  margin-top: 0.5rem;
  color: currentColor;
  animation: arrow 1.5s infinite $ease-in-out-cubic;

  @include mq(l) {
    width: 2.8rem;
    height: 2.8rem;
  }
}

@keyframes arrow {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  70% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}
