
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































































































































































.share {
  @include default-margin();
}

.share-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 1px solid $c-light-grey;

  @include mq(s) {
    flex-wrap: nowrap;
    align-items: center;
  }
}

.share__label {
  @extend %ff-alt;

  flex-basis: 100%;
  text-align: center;

  @include mq(s) {
    flex-basis: auto;
    margin-bottom: 0;
    text-align: left;
  }
}

.share__links {
  @extend %list-nostyle;

  display: flex;
}

.share__link {
  position: relative;

  & + & {
    padding-left: 1.5rem;
  }

  @include mq(s) {
    padding-left: 1.5rem;
  }
}

.share__link__tooltip {
  @extend %ff-alt;
  @extend %border-radius;
  @include center-x;

  top: math.div(-$spacing, 2);
  padding: 0 $spacing;
  color: $c-medium-grey;
  white-space: nowrap;
  transform: translate(-50%, -100%);
  border: 1px solid $c-light-grey;
  transition: 0.25s ease-out;
}

.tooltip-enter,
.tooltip-leave-to {
  opacity: 0;
  transform: translate(-50%, -80%);
}

.tooltip-leave-to {
  transform: translate(-50%, -120%);
}
